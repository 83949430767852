import React, { useState } from 'react';
import {
  Box,
  Button,
  VStack,
  Input,
  FormControl,
  FormLabel,
  Select,
  Heading,
  Text,
  FormErrorMessage,
  Textarea,
  useToast,
} from '@chakra-ui/react';
import emailjs from 'emailjs-com';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

const ContactUs: React.FC = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    employees: '',
    solution: '',
    usecase: '',
    prescriptions: '',
  });

  const [errors, setErrors] = useState({
    name: '',
    email: '',
    employees: '',
    solution: '',
    usecase: '',
    prescriptions: '',
  });

  const toast = useToast(); 
  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const validateForm = () => {
    let valid = true;
    let newErrors = { name: '', email: '', employees: '', solution: '', usecase: '', prescriptions: '' };

    if (!formData.name) {
      newErrors.name = 'Le nom est requis.';
      valid = false;
    }
    if (!formData.email) {
      newErrors.email = 'L\'adresse e-mail est requise.';
      valid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'L\'adresse e-mail est invalide.';
      valid = false;
    }
    if (!formData.employees) {
      newErrors.employees = 'Veuillez sélectionner le nombre d\'employés.';
      valid = false;
    }
    if (!formData.solution) {
      newErrors.solution = 'Veuillez sélectionner une solution.';
      valid = false;
    }
    if (!formData.usecase) {
      newErrors.usecase = 'Veuillez décrire ce que vous attendez de nous.';
      valid = false;
    }
    if (!formData.prescriptions) {
      newErrors.prescriptions = 'Veuillez sélectionner le nombre de prescriptions.';
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
  
    if (!validateForm()) {
      toast({
        title: 'Erreur de validation',
        description: 'Veuillez corriger les erreurs dans le formulaire.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }
  
    if (!executeRecaptcha) {
      toast({
        title: 'Erreur reCAPTCHA',
        description: 'reCAPTCHA n\'a pas pu être chargé. Veuillez réessayer.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }
  
    const recaptchaToken = await executeRecaptcha('submit');
    if (!recaptchaToken) {
      toast({
        title: 'Erreur reCAPTCHA',
        description: 'reCAPTCHA n\'a pas été validé. Veuillez réessayer.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }
  
    const formDataWithRecaptcha = { ...formData, recaptchaToken };
  
    emailjs.send(
      'service_6i3kcdg',
      'template_v151yxn',
      formDataWithRecaptcha,
      'NTjpYNJSLpPjgbx62'
    )
    .then((result) => {
      console.log(result.text);
      toast({
        title: 'E-mail envoyé',
        description: 'Votre e-mail a été envoyé avec succès.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    }, (error) => {
      console.log(error.text);
      toast({
        title: 'Erreur',
        description: "Erreur lors de l'envoi de l'e-mail.",
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    });
  };  

  return (
    <Box bg="blue.500" py={10} px={6}>
      <VStack spacing={4} mb={10} textAlign="center" color="white">
        <Heading fontSize="3xl">Commencez dès maintenant !</Heading>
        <Text fontSize="lg">
          Les experts de Ordonnance OCR Analyse et extractions des données vous montreront comment nos solutions intelligentes de traitement des Ordonnances peuvent être intégrées dans vos logiciels existants.
        </Text>
      </VStack>
      <Box bg="white" p={8} borderRadius="lg" shadow="lg" maxW="lg" mx="auto">
        <VStack spacing={4} as="form" onSubmit={handleSubmit}>
          <FormControl isInvalid={!!errors.name} isRequired>
            <FormLabel>Nom</FormLabel>
            <Input name="name" placeholder="Votre nom" value={formData.name} onChange={handleChange} />
            {errors.name && <FormErrorMessage>{errors.name}</FormErrorMessage>}
          </FormControl>
          <FormControl isInvalid={!!errors.email} isRequired>
            <FormLabel>Adresse e-mail professionnelle</FormLabel>
            <Input name="email" type="email" placeholder="Votre adresse e-mail" value={formData.email} onChange={handleChange} />
            {errors.email && <FormErrorMessage>{errors.email}</FormErrorMessage>}
          </FormControl>
          <FormControl isInvalid={!!errors.employees} isRequired>
            <FormLabel>Nombre d'employés</FormLabel>
            <Select name="employees" placeholder="Sélectionnez une option" value={formData.employees} onChange={handleChange}>
              <option value="1-10">1-10</option>
              <option value="11-50">11-50</option>
              <option value="51-200">51-200</option>
              <option value="201-500">201-500</option>
              <option value="500+">500+</option>
            </Select>
            {errors.employees && <FormErrorMessage>{errors.employees}</FormErrorMessage>}
          </FormControl>
          <FormControl isInvalid={!!errors.solution} isRequired>
            <FormLabel>Quelle est la solution qui vous intéresse ?</FormLabel>
            <Select name="solution" placeholder="Sélectionnez une option" value={formData.solution} onChange={handleChange}>
              <option value="transcription_ai_ordonnance">Transcription AI d'ordonnance</option>
              <option value="manuel_de_prelevement">Manuel de prélèvement</option>
              <option value="enregistrement_dematerialise">Enregistrement dématérialisé</option>
              <option value="envoi_resultats_patients">Envoi résultats patients</option>
            </Select>
            {errors.solution && <FormErrorMessage>{errors.solution}</FormErrorMessage>}
          </FormControl>
          <FormControl isInvalid={!!errors.usecase} isRequired>
            <FormLabel>Qu'attendez-vous de nous ?</FormLabel>
            <Textarea 
              name="usecase" 
              placeholder="Décrivez vos attentes" 
              value={formData.usecase} 
              onChange={handleChange} 
            />
            {errors.usecase && <FormErrorMessage>{errors.usecase}</FormErrorMessage>}
          </FormControl>
          <FormControl isInvalid={!!errors.prescriptions} isRequired>
            <FormLabel>Nombre de Ordonnances prévus à traiter par an</FormLabel>
            <Select name="prescriptions" placeholder="Sélectionnez une option" value={formData.prescriptions} onChange={handleChange}>
              <option value="0-10000">0 - 10,000</option>
              <option value="10000-50000">10,000 - 50,000</option>
              <option value="50000-100000">50,000 - 100,000</option>
              <option value="100000-500000">100,000 - 500,000</option>
              <option value="500000+">500,000+</option>
              <option value="unknown">Inconnu</option>
            </Select>
            {errors.prescriptions && <FormErrorMessage>{errors.prescriptions}</FormErrorMessage>}
          </FormControl>
          <Button colorScheme="blue" size="lg" width="full" type="submit">
            Envoyer
          </Button>
        </VStack>
      </Box>
    </Box>
  );
};

export default ContactUs;
