import React from 'react';
import { Box, SimpleGrid, Text, VStack, Button, Badge } from '@chakra-ui/react';
import { FcPrivacy, FcDocument } from "react-icons/fc";

const DemonstrationBis: React.FC = () => {
  return (
    <Box py={6} px={6} bg="gray.50">
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={8}>
        {/* Card 1 */}
        <Box
          bg="blue.50"
          p={6}
          rounded="lg"
          shadow="md"
          _hover={{ shadow: 'lg' }}
        >
          <VStack align="start" spacing={4}>
            <Badge colorScheme="blue" px={2} py={1} rounded="md">
              API d'envoi des résultats
            </Badge>
            <Text fontSize="xl" fontWeight="bold">
              Envoi sécurisé des résultats
            </Text>
            <Text color="gray.600">
              Utilisez notre API pour envoyer les résultats médicaux aux patients de manière sécurisée, protégée par un mot de passe, assurant la confidentialité des données.
            </Text>
            <Button as="a" href="https://my-cryptage-pdf-local-app.vercel.app" colorScheme="blue" size="md">
              En savoir plus
            </Button>
            <Box w="80%" h="200px" mx="auto" display="flex" justifyContent="center" alignItems="center">
              <FcPrivacy size="100%" />
            </Box>
          </VStack>
        </Box>
        {/* Card 2 */}
        <Box
          bg="blue.50"
          p={6}
          rounded="lg"
          shadow="md"
          _hover={{ shadow: 'lg' }}
        >
          <VStack align="start" spacing={4}>
            <Badge colorScheme="blue" px={2} py={1} rounded="md">
              Enregistrement dématérialisé
            </Badge>
            <Text fontSize="xl" fontWeight="bold">
              Enregistrement des dossiers à domicile
            </Text>
            <Text color="gray.600">
              Notre solution mobile permet aux patients d'enregistrer leurs dossiers médicaux directement depuis chez eux, en utilisant une application simple et sécurisée.
            </Text>
            <Button as="a" href="https://my-cryptage-pdf-local-app.vercel.app" colorScheme="blue" size="md">
              En savoir plus
            </Button>
            <Box w="80%" h="200px" mx="auto" display="flex" justifyContent="center" alignItems="center">
              <FcDocument size="100%" />
            </Box>
          </VStack>
        </Box>
      </SimpleGrid>
    </Box>
  );
};

export default DemonstrationBis;
