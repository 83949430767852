import React, { useState } from 'react';
import {
  Box,
  Button,
  Text,
  VStack,
  HStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
  Select,
  useDisclosure,
  FormControl,
  FormLabel,
  useToast,
} from '@chakra-ui/react';
import emailjs from 'emailjs-com';

interface PresentationProps {
  onDiscoverClick: () => void;
}

const Presentation: React.FC<PresentationProps> = ({ onDiscoverClick }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const [formData, setFormData] = useState({
    firstName: '',
    companyName: '',
    phone: '',
    date: '',
    time: '',
    heardFrom: '',
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = () => {
    emailjs.send(
      'service_6i3kcdg',  // Remplacez par votre Service ID
      'template_kvgg7qj',   // Remplacez par votre Template ID pour ce formulaire
      formData,           // Les données du formulaire
      'NTjpYNJSLpPjgbx62' // Remplacez par votre Public Key
    )
    .then((result) => {
      console.log(result.text);
      toast({
        title: 'Demande envoyée',
        description: 'Votre demande a été envoyée avec succès. Nous vous contacterons bientôt.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      onClose(); // Fermer le modal après envoi
    }, (error) => {
      console.log(error.text);
      toast({
        title: 'Erreur',
        description: "Une erreur est survenue lors de l'envoi de votre demande. Veuillez réessayer.",
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    });
  };

  return (
    <Box textAlign="center" py={{ base: 6, md: 10 }} px={{ base: 4, md: 6 }}>
      <VStack spacing={{ base: 4, md: 6 }}>
        <Text
          fontSize={{ base: 'xl', sm: '2xl', md: '4xl' }}
          fontWeight="bold"
          color="gray.800"
        >
          Plus efficace et moins cher grâce à la saisie automatique des Ordonnances par l’IA
        </Text>
        <Text fontSize={{ base: 'md', md: 'lg' }} color="gray.500">
          Réduisez les coûts opérationnels. Diminuer votre TAT (Turn Around Time) et le temps d'attente à l'accueil.
        </Text>
        <HStack
          spacing={{ base: 2, md: 4 }}
          flexDirection={{ base: 'column', sm: 'row' }}
          alignItems="center"
        >
          <Button colorScheme="blue" size="lg" width={{ base: 'full', sm: 'auto' }} onClick={onOpen}>
            Planifiez un appel
          </Button>
          <Button
            variant="outline"
            colorScheme="blue"
            size="lg"
            width={{ base: 'full', sm: 'auto' }}
            onClick={onDiscoverClick}
          >
            Découvrez nos produits
          </Button>
        </HStack>
      </VStack>

      {/* Modal for scheduling a call */}
      <Modal isOpen={isOpen} onClose={onClose} size="lg">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign="center">Planifiez un appel avec nous !</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text textAlign="center" mb={6}>
              Découvrez en 5-10 minutes comment nos solutions peuvent améliorer vos processus grâce à l'automatisation.
            </Text>
            <VStack spacing={4}>
              <FormControl isRequired>
                <FormLabel>Nom</FormLabel>
                <HStack>
                  <Input
                    name="firstName"
                    placeholder="Prénom"
                    value={formData.firstName}
                    onChange={handleChange}
                  />
                  <Input
                    name="companyName"
                    placeholder="Nom de l'entreprise"
                    value={formData.companyName}
                    onChange={handleChange}
                  />
                </HStack>
              </FormControl>
              <FormControl isRequired>
                <FormLabel>Indicatif & numéro de téléphone</FormLabel>
                <Input
                  name="phone"
                  type="tel"
                  placeholder="Votre numéro de téléphone"
                  value={formData.phone}
                  onChange={handleChange}
                />
              </FormControl>
              <FormControl isRequired>
                <FormLabel>Date & Heure</FormLabel>
                <HStack>
                  <Input
                    name="date"
                    type="date"
                    value={formData.date}
                    onChange={handleChange}
                  />
                  <Select
                    name="time"
                    placeholder="Heure (UTC+1)"
                    value={formData.time}
                    onChange={handleChange}
                  >
                    <option value="8:00">08:00</option>
                    <option value="9:00">09:00</option>
                    <option value="10:00">10:00</option>
                  </Select>
                </HStack>
              </FormControl>
              <FormControl isRequired>
                <FormLabel>Où avez-vous entendu parler de nous ?</FormLabel>
                <Select
                  name="heardFrom"
                  placeholder="Sélectionnez une option"
                  value={formData.heardFrom}
                  onChange={handleChange}
                >
                  <option value="web">Site Web</option>
                  <option value="friend">Un ami</option>
                  <option value="ad">Publicité</option>
                </Select>
              </FormControl>
            </VStack>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" size="lg" width="full" onClick={handleSubmit}>
              Appelez-moi
            </Button>
          </ModalFooter>
          <Text textAlign="center" mt={4} fontSize="sm" color="gray.500">
            Vous pouvez également nous contacter au <Text as="span" color="blue.600">+33 7 73 02 05 38</Text> pendant les heures de bureau (fuseau horaire CET). Si la date et l'heure de l'appel ne sont pas précisées, nous vous contacterons dans les 24 heures.
          </Text>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default Presentation;
