import React from 'react';
import { Box, SimpleGrid, Text, VStack, Button, Badge } from '@chakra-ui/react';
import { FcAssistant, FcServices } from "react-icons/fc";
import DemonstrationBis from './DemonstrationBis';

const Demonstration: React.FC = () => {
  return (
    <>
    <Box py={6} px={6} bg="gray.50">
      <VStack spacing={4} mb={10} textAlign="center">
        <Text fontSize={{ base: '2xl', sm: '3xl', md: '4xl' }} fontWeight="bold" color="gray.800">
          Le challenger du traitement automatisé des Ordonnances
        </Text>
        <Text fontSize="lg" color="gray.600" maxW="4xl">
          Optimisez vos opérations et réduisez considérablement vos coûts d'exploitation avec nos solutions de traitement des ordonnances alimentées par l'IA. Augmentez la productivité de vos équipes, diminuez vos besoins en recrutement et maximisez la satisfaction de vos employés. Offrez une expérience client innovante tout en maîtrisant vos dépenses grâce à l'expertise inégalée de notre technologie OCR.
        </Text>
      </VStack>
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={8}>
        {/* Card 1 */}
        <Box
          bg="blue.50"
          p={6}
          rounded="lg"
          shadow="md"
          _hover={{ shadow: 'lg' }}
        >
          <VStack align="start" spacing={4}>
            <Badge colorScheme="blue" px={2} py={1} rounded="md">
              Manuel de prélèvement et enregistrement
            </Badge>
            <Text fontSize="xl" fontWeight="bold">
              Manuel de prélèvement
            </Text>
            <Text color="gray.600">
              Notre Manuel de prélèvement dématérialisé et enregistrement à distance sont des outils complémentaires à votre laboratoire.
            </Text>
            <Button as="a" href="https://file-upload-app-ocr-ordo.vercel.app/" colorScheme="blue" size="md">
              En savoir plus
            </Button>
            <Box w="80%" h="200px" mx="auto" display="flex" justifyContent="center" alignItems="center">
              <FcAssistant size="100%" />
            </Box>
          </VStack>
        </Box>
        {/* Card 2 */}
        <Box
          bg="blue.50"
          p={6}
          rounded="lg"
          shadow="md"
          _hover={{ shadow: 'lg' }}
        >
          <VStack align="start" spacing={4}>
            <Badge colorScheme="blue" px={2} py={1} rounded="md">
              La saisie
            </Badge>
            <Text fontSize="xl" fontWeight="bold">
              Traitement intelligent des Ordonnances
            </Text>
            <Text color="gray.600">
              Ordonnance OCR Analyse et extractions des données depuis l'ordonnance reçue au laboratoire aide les organisations à automatiser les flux des données des Ordonnances vers leur logiciel métier.
            </Text>
            <Button as="a" href="https://file-upload-app-ocr-ordo.vercel.app/" colorScheme="blue" size="md">
              En savoir plus
            </Button>
            <Box w="80%" h="200px" mx="auto" display="flex" justifyContent="center" alignItems="center">
              <FcServices size="100%" />
            </Box>
          </VStack>
        </Box>
      </SimpleGrid>
    </Box>
    <DemonstrationBis/>
    </>
  );
};

export default Demonstration;
