import React from 'react';
import { Box, SimpleGrid, Icon, Text, VStack, HStack } from '@chakra-ui/react';
import { FaClock, FaChartBar, FaCog, FaShieldAlt } from 'react-icons/fa';

const InfoSections: React.FC = () => {
  const infoItems = [
    {
      icon: FaClock,
      title: 'Réduisez les délais d’enregistrement',
      description:
        'Réduisez considérablement les délais d’enregistrement en traitant les Ordonnances en 5 secondes, et non en quelques minutes. Proposez un enregistrement à distance',
    },
    {
      icon: FaChartBar,
      title: 'Économisez sur les coûts opérationnels',
      description:
        'Éliminez le travail de saisie des données et réduisez de vos coûts de secrétariat grâce à l’IA.',
    },
    {
      icon: FaCog,
      title: 'Optimisez l’efficacité de votre personnel',
      description:
        'Donnez à votre personnel les moyens d’en faire plus en moins de temps, en automatisant les tâches de saisie.',
    },
    {
      icon: FaShieldAlt,
      title: 'Diminuer les erreurs de saisie',
      description:
        'Ayez une saisie au secrétariat fiable et reproductible.',
    },
  ];

  return (
    <Box py={10} px={6}>
      <Text
        fontSize={{ base: '2xl', sm: '3xl', md: '4xl' }}
        fontWeight="bold"
        textAlign="center"
        mb={10}
      >
        Oubliez le traitement manuel des Ordonnances sauf les plus complexes et devenez un expert de l’automatisation
      </Text>
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
        {infoItems.map((item, index) => (
          <Box
            key={index}
            p={5}
            shadow="md"
            borderWidth="1px"
            borderRadius="lg"
            bg="white"
            _hover={{ shadow: 'lg' }}
          >
            <HStack align="start">
              <Icon as={item.icon} w={8} h={8} color="blue.500" />
              <VStack align="start" spacing={2}>
                <Text fontWeight="bold" fontSize="lg">
                  {item.title}
                </Text>
                <Text color="gray.500">{item.description}</Text>
              </VStack>
            </HStack>
          </Box>
        ))}
      </SimpleGrid>
    </Box>
  );
};

export default InfoSections;
