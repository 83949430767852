import React, { useRef } from 'react';
import { 
  Box, ChakraProvider, VStack, HStack, Button, useBreakpointValue, Flex, Spacer, 
  Menu, MenuButton, MenuList, MenuItem, IconButton, Image, Text 
} from '@chakra-ui/react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { GiHamburgerMenu } from 'react-icons/gi';
import { FcPhone } from "react-icons/fc";  // Import des icônes
import theme from './theme';
import Presentation from './components/Presentation';
import Demonstration from './components/Demonstration';
import ContactUs from './components/ContactUs';
import InfoSections from './components/InfoSections';

const App: React.FC = () => {
  const presentationRef = useRef<HTMLDivElement>(null);
  const demonstrationRef = useRef<HTMLDivElement>(null);
  const infoSectionsRef = useRef<HTMLDivElement>(null);
  const contactRef = useRef<HTMLDivElement>(null);

  const scrollToSection = (section: React.RefObject<HTMLDivElement>) => {
    if (section.current) {
      const yOffset = -100;
      const y = section.current.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  };

  const isSmallScreen = useBreakpointValue({ base: true, md: false });

  return (
    <ChakraProvider theme={theme}>
      <GoogleReCaptchaProvider reCaptchaKey="6LfXqSIqAAAAAFPrGZUy_ZU1R4etKGN2KksZR1Ii">
      <Box position="fixed" top="0" width="100%" bg="transparent" zIndex="1000" boxShadow="sm">
        <Flex justify="space-between" align="center" padding={4} mx="auto" mt={4} maxWidth="90%">
          <HStack spacing={4}>
            <Image 
              src="/logoordoo.webp" 
              alt="Logo Ordo" 
              boxSize="50px" 
              borderRadius="full" 
              boxShadow="md" 
              onClick={() => scrollToSection(presentationRef)} 
              cursor="pointer" 
            />
          </HStack>
          
          {isSmallScreen ? (
            <Menu>
              <MenuButton
                as={IconButton}
                aria-label="Ouvrir le menu"
                icon={<GiHamburgerMenu />}
                variant="outline"
                bg="white"
                borderRadius="full"
                boxShadow="md"
              />
              <MenuList borderRadius="md" boxShadow="lg" mt={2}>
                <MenuItem _hover={{ bg: 'gray.100' }}>
                  <HStack spacing={2} align="center">
                    <FcPhone size={24} />
                    <Text fontWeight="bold" color="gray.800" fontSize="lg">07 73 02 05 38</Text>
                  </HStack>
                </MenuItem>
                <MenuItem onClick={() => scrollToSection(presentationRef)} _hover={{ bg: 'gray.100' }}>
                  Présentation
                </MenuItem>
                <MenuItem onClick={() => scrollToSection(demonstrationRef)} _hover={{ bg: 'gray.100' }}>
                  Démonstration
                </MenuItem>
                <MenuItem onClick={() => scrollToSection(infoSectionsRef)} _hover={{ bg: 'gray.100' }}>
                  Nos Avantages
                </MenuItem>
                <MenuItem onClick={() => scrollToSection(contactRef)} _hover={{ bg: 'gray.100' }}>
                  Contactez-nous
                </MenuItem>
              </MenuList>
            </Menu>
          ) : (
            <HStack spacing={8}>
              <HStack spacing={8} justify="center" borderRadius="full" bg="white" boxShadow="md" p={4} height="60px">
                <Button variant="link" color="gray.800" fontWeight="bold" fontSize="lg" onClick={() => scrollToSection(presentationRef)}>
                  Présentation
                </Button>
                <Button variant="link" color="gray.800" fontWeight="bold" fontSize="lg" onClick={() => scrollToSection(demonstrationRef)}>
                  Démonstration
                </Button>
                <Button variant="link" color="gray.800" fontWeight="bold" fontSize="lg" onClick={() => scrollToSection(infoSectionsRef)}>
                  Nos Avantages
                </Button>
                <Button variant="link" color="gray.800" fontWeight="bold" fontSize="lg" onClick={() => scrollToSection(contactRef)}>
                  Contactez-nous
                </Button>
              </HStack>
              <Spacer />
              <HStack spacing={4}>
                <HStack spacing={2} align="center">
                  <FcPhone size={24} />
                  <Text fontWeight="bold" color="gray.800" fontSize="lg">07 73 02 05 38</Text>
                </HStack>
                <Button bg="white" variant="outline" colorScheme="blue" borderRadius="full" fontSize="lg" height="60px" onClick={() => scrollToSection(demonstrationRef)}>
                  Nos produits
                </Button>
                <Button colorScheme="blue" bg="blue.500" color="white" borderRadius="full" fontSize="lg" height="60px" onClick={() => scrollToSection(contactRef)}>
                  Contact
                </Button>
              </HStack>
            </HStack>
          )}
        </Flex>
      </Box>
      <VStack spacing={0} align="stretch" mt="120px">
        <Box id="presentation" ref={presentationRef}>
          <Presentation onDiscoverClick={() => scrollToSection(demonstrationRef)} />
        </Box>
        <Box id="demonstration" ref={demonstrationRef}>
          <Demonstration />
        </Box>
        <Box id="info-sections" ref={infoSectionsRef}>
          <InfoSections />
        </Box>
        <Box id="contact" ref={contactRef}>
          <ContactUs />
        </Box>
      </VStack>
      </GoogleReCaptchaProvider>
    </ChakraProvider>
  );
};

export default App;
